import { VueQueryPluginOptions } from '@tanstack/vue-query'
import {
  isDevelopmentEnvironment,
  isTestEnvironment,
} from '@/modules/base/config/env'

export const vueQueryConfig: VueQueryPluginOptions = {
  enableDevtoolsV6Plugin: true,
  queryClientConfig: {
    defaultOptions: {
      queries: {
        staleTime: 10000,
        // Disable retries in dev mode to make failing requests more obvious and speed up Cypress tests
        retry: isDevelopmentEnvironment || isTestEnvironment ? 0 : 3,
      },
    },
  },
}
